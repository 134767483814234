<template>
  <section id="Sidebar" class="col-12 col-md-3">
    <box-show-category
      title="ژانر فیلم ها"
      :ifValue="this.$store.state.getMovie.status.movieGenresLoading"
      :data="movieGenres"
      pathUrlName="GenreListMovie"
    >
    </box-show-category>
    <box-show-category
      title="شبکه تلوزیونی"
      :ifValue="this.$store.state.getMovie.status.tvGenresLoading"
      :data="tvGenres"
      pathUrlName="GenreListTV"
    >
    </box-show-category>
    <box-search-movie />
    <box-search-tv />
  </section>
</template>

<script>
import boxShowCategory from "@/components/template/boxShowCategory.vue";
import BoxSearchMovie from "@/components/template/boxSearchMovie";
import BoxSearchTv from "@/components/template/boxSearchTv";
export default {
  name: "TheSidebar",
  components: { BoxSearchTv, BoxSearchMovie, boxShowCategory },
  computed: {
    movieGenres() {
      return this.$store.state.getMovie.movieGenres;
    },
    tvGenres() {
      return this.$store.state.getMovie.tvGenres;
    },
  },
  methods: {
    getMovieGenres() {
      let query = `genre/movie/list?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setMovieGenres",
      });
    },
    getTvGenres() {
      let query = `genre/tv/list?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setTvGenres",
      });
    },
  },
  mounted() {
    this.getMovieGenres();
    this.getTvGenres();
  },
};
</script>
