<template>
  <div class="card mb-2">
    <div class="card-header bg-color-primary-4 text-white text-center">
      جستجوی سریع سریال
    </div>
    <div class="card-body">
      <div class="position-relative">
        <input
          class="form-control"
          placeholder="انگلیسی بنویسید."
          v-model="searchInTv"
          @keyup="searchTv"
        />
        <i
          class="d-inline-flex align-middle mdi mdi-close mdi-24px position-absolute"
          style="cursor: pointer; top: 1px; left: 8px"
          v-if="
            this.$store.state.getMovie.status.searchTvLoading &&
            searchTypeTv.length > 0
          "
          @click="resetSearchTv"
        ></i>
      </div>
      <template
        v-if="
          this.$store.state.getMovie.status.searchTvLoading &&
          searchTypeTv.length > 0
        "
      >
        <ul class="list-group">
          <li
            class="list-group-item p-1"
            v-for="movie in searchTypeTv"
            :key="movie.id"
          >
            <router-link
              :to="{ name: 'SingleTv', params: { id: movie.id } }"
              class="d-flex align-items-center justify-content-between"
            >
              <img
                v-if="movie.poster_path"
                class="img-fluid me-1"
                style="min-width: 54px; max-width: 54px"
                :src="imageSizeMovie('w185') + movie.poster_path"
                :title="movie.original_name"
                :alt="movie.original_name"
              />
              <img
                v-else
                class="img-fluid w-25 me-1"
                style="min-width: 54px; max-width: 54px"
                src="../../assets/no_image.jpg"
                :title="movie.original_name"
                :alt="movie.original_name"
              />
              <div dir="ltr" class="text-end">
                {{ movie.original_name }}
              </div>
            </router-link>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "boxSearchTv",
  data() {
    return {
      searchInTv: "",
    };
  },
  computed: {
    searchTypeTv() {
      return this.$store.state.getMovie.searchTv;
    },
  },
  methods: {
    searchTv() {
      if (this.searchInTv.length >= 3) {
        let query = `search/tv/?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&query=${this.searchInTv}&page=1&include_adult=false`;
        this.$store.dispatch("getMovie/fetchData", {
          query: query,
          setName: "setSearchTv",
        });
      }
    },
    resetSearchTv() {
      this.searchInTv = "";
      this.$store.commit("getMovie/setResetSearchTv");
    },
  },
  unmounted() {
    this.resetSearchTv();
  },
};
</script>

<style scoped>
.list-group {
  max-height: 250px;
  overflow-y: auto;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
</style>
