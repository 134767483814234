<template>
  <div class="card mb-2">
    <div class="card-header bg-color-primary-1 text-white text-center">
      {{ title }}
    </div>
    <div class="card-body">
      <ul class="list-group list-group-flush" v-if="ifValue">
        <li
          class="list-group-item p-0"
          v-for="movieGenre in data"
          :key="movieGenre.id"
        >
          <router-link
            :to="{
              name: pathUrlName,
              params: { id: movieGenre.id },
              query: { genreName: movieGenre.name },
            }"
            class="d-flex justify-content-between align-content-center p-2"
          >
            <strong>{{ translatePersian(movieGenre.name) }}</strong>
            <span>({{ movieGenre.name }})</span>
          </router-link>
        </li>
      </ul>
      <spinner v-else></spinner>
    </div>
  </div>
</template>

<script>
import spinner from "@/components/template/spinner";
export default {
  name: "boxShowCategory",
  components: { spinner },
  props: {
    title: String,
    ifValue: Boolean,
    data: Object,
    pathUrlName: String,
  },
};
</script>

<style scoped>
#Sidebar .list-group-item a:hover {
  background-color: #f2f2f2;
}
</style>
